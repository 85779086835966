<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("History Risk Reports") }}</h3>
      </div>
    </div>
    <div class="card-body detail">
      <secondment-reports-table :secondment-reports="reports"></secondment-reports-table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SecondmentReportsTable from "@/view/components/tables/SecondmentReportsTable.vue";
import SecondmentService from "@/core/services/secondment/secondment.service";
export default {
  components: {
    SecondmentReportsTable,
  },

  props: {
    secondment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reports: [],
      territorial_jurisdiction: null
    }
  },
  watch: {
    '$i18n.locale': async function () {
      await SecondmentService.getOne({ id: this.secondment.id, fields: 'reports' }).then(response => {
        this.reports = response.data.reports
      })
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("History Risk Reports") },
    ]);
    await SecondmentService.getOne({ id: this.secondment.id, fields: 'reports' }).then(response => {
      this.reports = response.data.reports
    })
  }
};
</script>
