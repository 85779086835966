var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('datatable', {
    attrs: {
      "table-props": _vm.tableProps,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "topbar",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('euro-input', {
          attrs: {
            "label": _vm.$t('Report title')
          },
          model: {
            value: _vm.filters.title,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "title", $$v);
            },
            expression: "filters.title"
          }
        }), _c('euro-select', {
          staticClass: "w-50 mx-4",
          attrs: {
            "multiselect-props": {
              options: _vm.reportTypes,
              multiple: true
            },
            "label": _vm.$t('Report type')
          },
          model: {
            value: _vm.filters.types,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "types", $$v);
            },
            expression: "filters.types"
          }
        }), _c('date-picker', {
          attrs: {
            "overrides": {
              useCurrent: false
            },
            "label": _vm.$t('Date')
          },
          model: {
            value: _vm.filters.date,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "date", $$v);
            },
            expression: "filters.date"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "cell.report_date",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-bolder text-muted"
        }, [_vm._v(" " + _vm._s(item.report_date ? _vm.DateService.format(item.report_date, _vm.dateFormat) : "") + " ")])];
      }
    }, {
      key: "cell.report_type",
      fn: function fn(_ref2) {
        var _vm$reportTypeMap$ite, _vm$reportTypeMap$ite2;
        var item = _ref2.item;
        return [_c('b-badge', {
          staticClass: "w-100 text-uppercase",
          attrs: {
            "variant": (_vm$reportTypeMap$ite = (_vm$reportTypeMap$ite2 = _vm.reportTypeMap[item === null || item === void 0 ? void 0 : item.report_type]) === null || _vm$reportTypeMap$ite2 === void 0 ? void 0 : _vm$reportTypeMap$ite2.variant) !== null && _vm$reportTypeMap$ite !== void 0 ? _vm$reportTypeMap$ite : ''
          }
        }, [_vm._v(" " + _vm._s(item.report_type) + " ")])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('btn-icon', {
          attrs: {
            "icon": "flaticon2-heart-rate-monitor",
            "tooltip": _vm.$t('Show Report Datail')
          },
          on: {
            "click": function click($event) {
              _vm.showReport(item.report.json_report), _vm.category = item.title;
            }
          }
        })];
      }
    }], null, true)
  }), _vm.detailReport ? _c('SecondmentReportDetailTableVue', {
    staticClass: "mt-6",
    attrs: {
      "reports": _vm.detailReport,
      "category": _vm.category
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }