var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("History Risk Reports")))])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('secondment-reports-table', {
    attrs: {
      "secondment-reports": _vm.reports
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }