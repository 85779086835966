<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-100 mt-6 border-top pt-6">
    <b-overlay :show="!loaded" opacity="0.3" class="mt-6">
      <div v-if="!loaded" class="min-vh-60 d-flex flex-column align-items-center justify-content-center">
        <div class="text-center mb-20">
          <h4>{{ $t("Loading reports...") }}</h4>
        </div>
      </div>
    </b-overlay>
    <div class="pb-6">
      <h1>{{ $t("Show detail report") }} "{{ category }}""</h1>
      <div class="mt-5">
        <div class="d-flex align-items-center">
          <euro-select v-model="countrySort" :placeholder="$t('Ordina per fonte')" :options="countriesComputed"
            searchable style="min-width: 180px;max-width: 180px;" class-name="my-0"></euro-select>
          <euro-select v-model="countryNumbertSort" :placeholder="$t('Sorting')" :options="valueNumbertSort"
            style="min-width: 110px;max-width: 110px;" class-name="ml-4 my-0"></euro-select>
          <euro-select v-model="countryFilter" :placeholder="$t('Filtra per fonte')" :options="countriesComputed"
            searchable style="min-width: 180px;max-width: 180px;" class-name="ml-4 my-0"></euro-select>
          <euro-select v-model="categoryFilter" :placeholder="$t('Filtra per categoria')" :options="categories"
            searchable style="min-width: 350px;max-width: 350px;" class-name="ml-4 my-0"></euro-select>
        </div>
      </div>
    </div>
    <b-overlay :show="!sorted" opacity="0.3" class="mt-6">
      <div v-if="!sorted" class="min-vh-60 d-flex flex-column align-items-center justify-content-center">
        <div class="text-center mb-20">
          <h4>{{ $t("Loading...") }}</h4>
        </div>
      </div>
    </b-overlay>
    <div v-for="(result, index) in getResults" :key="index" class="border mb-5">
      <div class="border-bottom px-6 py-5 bg-light">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h3 class="mb-0">{{ result.categories.map(cat => cat.name).join(',') }}</h3>
            <div class="d-flex align-items-center flags ml-4">
              <country-flag v-for="country in result.countries" :key="`data-${index}-top-${country}`"
                :country-iso="country" class="mb-0 mr-2"></country-flag>
            </div>
          </div>
          <div>
            <b-dropdown v-b-tooltip.hover="$t('Show source detail to specific country')" no-caret right no-flip
              :text="$t('Sources')" toggle-class="font-weight-bold">
              <div class="navi navi-hover">
                <b-dropdown-item v-for="(source, i) in result.sources" :key="i" tag="div" class="navi-item"
                  @click="modalReport(source, result.title)">
                  {{ countriesMap[source.territorial_jurisdiction].name }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div v-for="(data, indexData) in result.data" :key="`data-${index}-${indexData}-${data.id}`"
        class="border-top px-2" :class="{ 'bg-select-row-ys': countryIsIncludes(countrySort, data) }">
        <div class="d-flex">
          <div class="col-3 border-right py-4">
            <h4>{{ data.title }}</h4>
            <div>
              <span v-if="data.countries" class="label label-inline label-light-warning">REPEATED</span>
              <span v-else class="label label-inline label-light-success">UNIQUE</span>
            </div>
          </div>
          <div class="col-1 border-right py-4 flags">
            <div v-if="data.countries" class="text-center">
              <country-flag v-for="country in data.countries" :key="`data-${index}-${indexData}-${data.id}-${country}`"
                :country-iso="country" class="mb-4 mr-2"></country-flag>
            </div>
            <div v-if="data.country" class="text-center">
              <country-flag :country-iso="data.country"></country-flag>
            </div>
          </div>
          <div class="col-8  py-4">
            <p>{{ data.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="report-modal-detail" size="xl" hide-footer>
      <template #modal-header>
        <div class="d-flex justify-content-between align-items-center py-0 w-100">
          <div>
            <h3 class="font-weight-bolder">{{ showReportSource.territorial_jurisdiction }} {{ $t("Source") }}</h3>
            <h5 class="my-0 font-weight-bold">
              <span class="badge align-self-start badge-success">{{ showReportSource.title }}</span>
            </h5>
          </div>
          <div class="d-flex align-items-start">
            <b-button @click="$bvModal.hide('report-modal-detail')">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <div v-if="showReportSource">
        <div v-html="showReportSource.body"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CountryFlag
  },
  props: {
    reports: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      categoryFilter: null,
      countryFilter: null,
      countrySort: null,
      countryNumbertSort: null,
      countries: [],
      loaded: false,
      sorted: true,
      showReportSource: false
    }
  },
  computed: {
    ...mapGetters("constants", ["countriesMap"]),
    countriesComputed() {
      const selectable = [];
      this.countries.forEach((el) => {
        if (this.countriesMap[el]) {
          selectable.push({
            text: this.countriesMap[el].name,
            value: this.countriesMap[el].iso,
          });
        }
      });
      return selectable;
    },
    categories() {
      let cats = []
      this.reports.forEach(report => {
        cats = cats.concat(report.categories)
      });
      return cats.map(cat => ({ value: cat.id, text: cat.name }))
    },
    getResults() {
      return this.getFiltredResults()
    },
    valueNumbertSort() {
      return [
        { value: "asc", text: "Da 5 a 1" },
        { value: "desc", text: "Da 1 a 5" }
      ]
    }
  },
  methods: {
    getCountries() {
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }

      let reportCountries = []
      this.reports.forEach(report => {
        reportCountries = reportCountries.concat(report.ai_post_sources_territorial_jurisdictions)
      })

      this.countries = reportCountries.filter(onlyUnique)
    },
    getFiltredResults() {
      this.loaded = false
      let results = []
      this.reports.forEach(report => {
        if (report.body_json != null && (this.categoryFilter == null || report.categories.map(el => el.id).includes(this.categoryFilter))) {
          const block = {
            title: report.title,
            categories: report.categories,
            countries: report.ai_post_sources_territorial_jurisdictions,
            data: [],
            sources: report.sources_json
          }
          if (report.body_json.repeated_information.length) {
            report.body_json.repeated_information.forEach(information => {
              if (this.countryFilter == null || information.countries.includes(this.countryFilter)) {
                block.data.push(information)
              }
            })
          }
          report.body_json.unique_information.forEach(information => {
            if (this.countryFilter == null || information.country.includes(this.countryFilter)) {
              block.data.push(information)
            }
          })

          if (this.countryFilter == null) {
            results.push(block)
          }

          if (this.countryFilter && block.data.length) {
            results.push(block)
          }
        }
      })

      this.loaded = true

      // Aggiorno lista fonti
      this.getCountries()

      return this.sortToCountry(results)
    },
    sortToCountry(results) {
      this.sorted = false
      if (!this.countrySort && !this.countryNumbertSort) {
        this.sorted = true
        return results
      }

      this.sorted = false
      let resultSorted = results

      if (this.countrySort) {
        let reportsFirst = []
        let reportsSecond = []
        results.forEach(result => {
          if (result.countries.includes(this.countrySort)) {
            let reportCountryIncluded = []
            let reportCountryNotIncluded = []
            result.data.forEach(res => {
              let countryArray = []
              if (res.countries) {
                countryArray = res.countries
              } else {
                countryArray = res.country
              }

              if (countryArray.includes(this.countrySort)) {
                reportCountryIncluded.push(res)
              } else {
                reportCountryNotIncluded.push(res)
              }
            })
            result.data = reportCountryIncluded.concat(reportCountryNotIncluded)
            reportsFirst.push(result)
          } else {
            reportsSecond.push(result)
          }
        })

        resultSorted = reportsFirst.concat(reportsSecond)
      }

      if (this.countryNumbertSort) {
        resultSorted.forEach(element => {
          element.data = this.sortByInternalCountries(element.data)
          return element
        })
      }

      this.sorted = true
      return resultSorted
    },
    sortByInternalCountries(reports) {
      return reports.sort((a, b) => {
        let arrOne = []
        let arrTwo = []
        if (a.countries) {
          arrOne = a.countries
        } else {
          arrOne = [a.country]
        }
        if (b.countries) {
          arrTwo = b.countries
        } else {
          arrTwo = [b.country]
        }
        if (this.countryNumbertSort == 'desc') {
          return arrOne.length - arrTwo.length
        } else {
          return arrTwo.length - arrOne.length
        }
      })
    },
    modalReport(source, title) {
      this.showReportSource = false
      this.showReportSource = JSON.parse(JSON.stringify(source))
      this.showReportSource['title'] = title
      this.$bvModal.show('report-modal-detail')
    },
    countryIsIncludes(country, row) {
      if (!country) {
        return false
      }

      if (row.countries) {
        return row.countries.includes(country)
      } else {
        return row.country == country
      }
    }
  }
}
</script>

<style>
.flags .country-flag {
  min-height: 18px;
  width: 24px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, .5);
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  width: 25px !important;
  height: 25px;
  border-radius: 40px;
  min-width: 25px;
}

.bg-select-row-ys {
  background-color: #ffec000d;
}
</style>