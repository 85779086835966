var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 mt-6 border-top pt-6"
  }, [_c('b-overlay', {
    staticClass: "mt-6",
    attrs: {
      "show": !_vm.loaded,
      "opacity": "0.3"
    }
  }, [!_vm.loaded ? _c('div', {
    staticClass: "min-vh-60 d-flex flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "text-center mb-20"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Loading reports...")))])])]) : _vm._e()]), _c('div', {
    staticClass: "pb-6"
  }, [_c('h1', [_vm._v(_vm._s(_vm.$t("Show detail report")) + " \"" + _vm._s(_vm.category) + "\"\"")]), _c('div', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('euro-select', {
    staticStyle: {
      "min-width": "180px",
      "max-width": "180px"
    },
    attrs: {
      "placeholder": _vm.$t('Ordina per fonte'),
      "options": _vm.countriesComputed,
      "searchable": "",
      "class-name": "my-0"
    },
    model: {
      value: _vm.countrySort,
      callback: function callback($$v) {
        _vm.countrySort = $$v;
      },
      expression: "countrySort"
    }
  }), _c('euro-select', {
    staticStyle: {
      "min-width": "110px",
      "max-width": "110px"
    },
    attrs: {
      "placeholder": _vm.$t('Sorting'),
      "options": _vm.valueNumbertSort,
      "class-name": "ml-4 my-0"
    },
    model: {
      value: _vm.countryNumbertSort,
      callback: function callback($$v) {
        _vm.countryNumbertSort = $$v;
      },
      expression: "countryNumbertSort"
    }
  }), _c('euro-select', {
    staticStyle: {
      "min-width": "180px",
      "max-width": "180px"
    },
    attrs: {
      "placeholder": _vm.$t('Filtra per fonte'),
      "options": _vm.countriesComputed,
      "searchable": "",
      "class-name": "ml-4 my-0"
    },
    model: {
      value: _vm.countryFilter,
      callback: function callback($$v) {
        _vm.countryFilter = $$v;
      },
      expression: "countryFilter"
    }
  }), _c('euro-select', {
    staticStyle: {
      "min-width": "350px",
      "max-width": "350px"
    },
    attrs: {
      "placeholder": _vm.$t('Filtra per categoria'),
      "options": _vm.categories,
      "searchable": "",
      "class-name": "ml-4 my-0"
    },
    model: {
      value: _vm.categoryFilter,
      callback: function callback($$v) {
        _vm.categoryFilter = $$v;
      },
      expression: "categoryFilter"
    }
  })], 1)])]), _c('b-overlay', {
    staticClass: "mt-6",
    attrs: {
      "show": !_vm.sorted,
      "opacity": "0.3"
    }
  }, [!_vm.sorted ? _c('div', {
    staticClass: "min-vh-60 d-flex flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "text-center mb-20"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Loading...")))])])]) : _vm._e()]), _vm._l(_vm.getResults, function (result, index) {
    return _c('div', {
      key: index,
      staticClass: "border mb-5"
    }, [_c('div', {
      staticClass: "border-bottom px-6 py-5 bg-light"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('h3', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(result.categories.map(function (cat) {
      return cat.name;
    }).join(',')))]), _c('div', {
      staticClass: "d-flex align-items-center flags ml-4"
    }, _vm._l(result.countries, function (country) {
      return _c('country-flag', {
        key: "data-".concat(index, "-top-").concat(country),
        staticClass: "mb-0 mr-2",
        attrs: {
          "country-iso": country
        }
      });
    }), 1)]), _c('div', [_c('b-dropdown', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.$t('Show source detail to specific country'),
        expression: "$t('Show source detail to specific country')",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "no-caret": "",
        "right": "",
        "no-flip": "",
        "text": _vm.$t('Sources'),
        "toggle-class": "font-weight-bold"
      }
    }, [_c('div', {
      staticClass: "navi navi-hover"
    }, _vm._l(result.sources, function (source, i) {
      return _c('b-dropdown-item', {
        key: i,
        staticClass: "navi-item",
        attrs: {
          "tag": "div"
        },
        on: {
          "click": function click($event) {
            return _vm.modalReport(source, result.title);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.countriesMap[source.territorial_jurisdiction].name) + " ")]);
    }), 1)])], 1)])]), _vm._l(result.data, function (data, indexData) {
      return _c('div', {
        key: "data-".concat(index, "-").concat(indexData, "-").concat(data.id),
        staticClass: "border-top px-2",
        class: {
          'bg-select-row-ys': _vm.countryIsIncludes(_vm.countrySort, data)
        }
      }, [_c('div', {
        staticClass: "d-flex"
      }, [_c('div', {
        staticClass: "col-3 border-right py-4"
      }, [_c('h4', [_vm._v(_vm._s(data.title))]), _c('div', [data.countries ? _c('span', {
        staticClass: "label label-inline label-light-warning"
      }, [_vm._v("REPEATED")]) : _c('span', {
        staticClass: "label label-inline label-light-success"
      }, [_vm._v("UNIQUE")])])]), _c('div', {
        staticClass: "col-1 border-right py-4 flags"
      }, [data.countries ? _c('div', {
        staticClass: "text-center"
      }, _vm._l(data.countries, function (country) {
        return _c('country-flag', {
          key: "data-".concat(index, "-").concat(indexData, "-").concat(data.id, "-").concat(country),
          staticClass: "mb-4 mr-2",
          attrs: {
            "country-iso": country
          }
        });
      }), 1) : _vm._e(), data.country ? _c('div', {
        staticClass: "text-center"
      }, [_c('country-flag', {
        attrs: {
          "country-iso": data.country
        }
      })], 1) : _vm._e()]), _c('div', {
        staticClass: "col-8 py-4"
      }, [_c('p', [_vm._v(_vm._s(data.text))])])])]);
    })], 2);
  }), _c('b-modal', {
    attrs: {
      "id": "report-modal-detail",
      "size": "xl",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center py-0 w-100"
        }, [_c('div', [_c('h3', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.showReportSource.territorial_jurisdiction) + " " + _vm._s(_vm.$t("Source")))]), _c('h5', {
          staticClass: "my-0 font-weight-bold"
        }, [_c('span', {
          staticClass: "badge align-self-start badge-success"
        }, [_vm._v(_vm._s(_vm.showReportSource.title))])])]), _c('div', {
          staticClass: "d-flex align-items-start"
        }, [_c('b-button', {
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide('report-modal-detail');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_vm.showReportSource ? _c('div', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.showReportSource.body)
    }
  })]) : _vm._e()])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }