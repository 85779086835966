<template>
  <div>
    <datatable :table-props="tableProps" :options.sync="tableOptions" :per-page-options="perPageOptions">
      <template #topbar>
        <div class="d-flex">
          <euro-input v-model="filters.title" :label="$t('Report title')"></euro-input>
          <euro-select v-model="filters.types" class="w-50 mx-4" :multiselect-props="{
      options: reportTypes,
      multiple: true,
    }" :label="$t('Report type')"></euro-select>
          <date-picker v-model="filters.date" :overrides="{ useCurrent: false }" :label="$t('Date')"></date-picker>
        </div>
      </template>

      <template #[`cell.report_date`]="{ item }">
        <span class="font-weight-bolder text-muted">
          {{ item.report_date ? DateService.format(item.report_date, dateFormat) : "" }}
        </span>
      </template>

      <template #[`cell.report_type`]="{ item }">
        <b-badge class="w-100 text-uppercase" :variant="reportTypeMap[item?.report_type]?.variant ?? ''">
          {{ item.report_type }}
        </b-badge>
      </template>

      <template #[`cell.actions`]="{ item }">
        <btn-icon icon="flaticon2-heart-rate-monitor" :tooltip="$t('Show Report Datail')"
          @click="showReport(item.report.json_report), category = item.title"></btn-icon>
      </template>
    </datatable>
    <SecondmentReportDetailTableVue v-if="detailReport" :reports="detailReport" :category="category" class="mt-6" />
  </div>
</template>

<script>
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import SecondmentReportDetailTableVue from '@/view/components/tables/SecondmentReportDetailTable.vue';
export default {
  components: {
    DatePicker,
    SecondmentReportDetailTableVue
  },
  props: {
    secondmentReports: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      DateService,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      filters: {
        title: "",
        types: [],
        date: null,
      },
      detailReport: null,
      category: ''
    };
  },
  computed: {
    ...mapGetters("user", ["dateFormat"]),

    reportTypeMap() {
      return {
        pre: {
          variant: "primary",
        },
        start: {
          variant: "success",
        },
        update: {
          variant: "warning",
        },
        extra: {
          variant: "info",
        },
        end: {
          variant: "danger",
        },
      };
    },

    reportTypes() {
      return Object.keys(this.reportTypeMap).map(type => {
        return { value: type, text: type }
      });
    },

    secondmentReportsFiltered() {
      const datas = JSON.parse(JSON.stringify(this.secondmentReports))
      let out = [...datas];

      const { title: titleFilter, types: typesFilter, date: dateFilter } = this.filters;

      if (titleFilter) {
        out = out.filter(report => report.title.toLowerCase().includes(titleFilter.toLowerCase()));
      }

      if (typesFilter.length) {
        out = out.filter(report => typesFilter.includes(report.report_type));
      }

      if (dateFilter) {
        out = out.filter(report => DateService.diff(report.report_date, dateFilter) == 0);
      }

      return out;
    },

    tableProps() {
      return {
        items: this.secondmentReportsFiltered,
        fields: [
          {
            label: this.$t("Report title"),
            key: "title",
            class: ["font-weight-bolder"],
            sortable: true,
          },
          {
            label: this.$t("Report type"),
            key: "report_type",
            class: ["font-weight-bolder"],
            sortable: true,
          },
          { label: this.$t("Date"), key: "report_date", class: ["font-weight-bolder"], sortable: true },
          { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
        ],
      };
    },
  },
  watch: {
    secondmentReports: {
      deep: true,
      async handler() {
        this.detailReport = null
      }
    }
  },
  methods: {
    showReport(reports) {
      this.detailReport = reports
    },
  },
};
</script>
